import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocalStorage } from '@shared/utils';

import { Header } from './ui/header/Header';
import { Router } from './Router';
import { Footer } from './ui/footer/Footer';
import { useDidMount } from '@/shared/hooks/useDidMount';
import { UserService } from '@/shared/api';
import { useAuth } from './AuthHook';

export const App: React.FC = () => {
    const [, i18n] = useTranslation();
    const [lang] = useLocalStorage('lang', 'ru');
    const { setUser } = useAuth();

    useDidMount(() => {
        const updateHtmlLang = (lng: string) => {
            document.documentElement.lang = lng;
            document.querySelector('meta[http-equiv="Content-Language"]')?.setAttribute('content', lng);
        };
        updateHtmlLang(i18n.language);
        i18n.on('languageChanged', updateHtmlLang);

        if (setUser)
            UserService.getUser().then((resp) =>
                setUser({
                    ID: resp.ID || '',
                    Name: resp.Name || '',
                    Username: resp.Username || '',
                    Avatar: resp.PictureURL || '',
                    EMail: '',
                })
            );

        return () => {
            i18n.off('languageChanged', updateHtmlLang);
        };
    });

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [i18n, lang]);

    return (
        <div className="app">
            <Header />
            <Router />
            <Footer />
        </div>
    );
};
