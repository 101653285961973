import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import './global.scss';

import { App } from './App';
import { AuthProvider } from '@app/AuthProvider';
import { StoreProvider } from './store/store';
import { BrowserRouter } from 'react-router-dom';
import ScrollRestoration from './ScrollRestoration';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Suspense fallback={<div>Loading...</div>}>
            <BrowserRouter>
                <ScrollRestoration />
                <AuthProvider>
                    <StoreProvider>
                        <App />
                    </StoreProvider>
                </AuthProvider>
            </BrowserRouter>
        </Suspense>
    </React.StrictMode>
);
