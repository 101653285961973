import React, { ReactNode, useState } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Language } from '../language/Language';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import logo from './assets/logo.svg';
import hamburger from './assets/hamburger.svg';
import close from './assets/close.svg';
import classNames from 'classnames';
import { Donate } from '@/features/changeDonate/ui/Donate';
import { useIsMobile } from '@/shared/utils';
import { UserButtons } from './ui/UserButtons/UserButtons';
import { Tooltip } from 'react-tooltip';

export const Header: React.FC = () => {
    const [active, setActive] = useState(false);
    const isMobile = useIsMobile(900);
    const [tooltipContent, setTooltipContent] = useState<ReactNode | null>(null);
    const handleToggle = () => {
        setActive((prev) => {
            document.body.style.overflow = prev ? '' : 'hidden';
            return !prev;
        });
    };
    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <div style={{ display: 'flex' }}>
                    <span className={styles.burger} onClick={handleToggle}>
                        <img src={hamburger} alt="BurgerMenu" />
                    </span>
                    <Link className={styles.logo} to="/">
                        <span className={styles.LogoTitle}>{t('title').toUpperCase()}</span>
                        <img src={logo} alt="Go Companion" />
                    </Link>
                </div>

                <nav className={classNames(styles.navbar, { [styles['navbar_active']]: active })}>
                    <span className={styles.close} onClick={handleToggle}>
                        <img src={close} alt="close menu" />
                    </span>
                    <Link className={styles['navbar-item']} to="/players" onClick={active ? handleToggle : undefined}>
                        <Trans i18nKey="menuPlayers" />
                    </Link>
                    <Link
                        className={styles['navbar-item']}
                        to="/tournaments"
                        onClick={active ? handleToggle : undefined}
                    >
                        <Trans i18nKey="menuTournaments" />
                    </Link>
                    <Link className={styles['navbar-item']} to="/about" onClick={active ? handleToggle : undefined}>
                        <Trans i18nKey="menuAboutCompany" />
                    </Link>
                    {isMobile && <Donate />}
                </nav>
                <div className={styles.end}>
                    {!isMobile && <Donate />}
                    <Language setTooltipContent={setTooltipContent} />
                    <UserButtons setTooltipContent={setTooltipContent} />
                    <Tooltip
                        id="header-end-tooltip"
                        className={styles.tooltip}
                        clickable
                        opacity={1}
                        variant="light"
                        noArrow
                        openOnClick={isMobile}
                    >
                        {tooltipContent}
                    </Tooltip>
                </div>
            </header>
        </div>
    );
};
